<template>
  <Popup ref="popupRef" width="610px" title="新建商务提成核算" confirm-text="确认" @confirm="confirm" @close="resetForm">
    <el-form label-position="top">
      <el-form-item label="商户名称" required>
        <el-input placeholder="请输入商户名称"></el-input>
      </el-form-item>
      <el-form-item label="注册账号">
        <el-input placeholder="请输入注册账号"></el-input>
      </el-form-item>
      <el-form-item label="订单类型" required>
        <el-radio-group v-model="form.orderType">
          <el-radio :label='item.type' v-for="(item, index) in orderTypeList" :key="index">{{item.name}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="金额">
        <el-input placeholder="请输入金额"></el-input>
      </el-form-item>
      <el-form-item label="支付方">
        <el-input placeholder="请输入支付方姓名或公司"></el-input>
      </el-form-item>
      <el-form-item label="收款方">
        <el-input placeholder="请输入收款方姓名或公司"></el-input>
      </el-form-item>
      <el-form-item label="是否需要开票">
        <el-radio-group v-model="form.invoiceNeed">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="开票抬头(专票/普票)" v-if="form.invoiceNeed">
        <el-input placeholder="请输入开票抬头"></el-input>
      </el-form-item>
      <el-form-item label="签约类型">
        <el-radio-group>
          <el-radio :label="0">新签约</el-radio>
          <el-radio :label="1">复购</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上传支付凭证">
        <UploadImage></UploadImage>
      </el-form-item>
    </el-form>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import UploadImage from '@/components/UploadImage'
export default {
  components: {
    Popup,
    UploadImage
  },
  data() {
    return {
      form: {
        orderType: 0,
        registerDate: '',
        invoiceNeed: true
      },
      orderTypeList: [
        {name:'报名单',type:0},
        {name:'会员',type:1},
        {name:'点券',type:2},
        {name:'弹窗广告',type:3},
        {name:'保证金',type:4}
      ]
    }
  },
  methods: {
    open(option) {
      this.$refs.popupRef.open()
    },
    resetForm() {

    },
    confirm() {

    }
  }
}
</script>

<style lang="scss" scoped>

</style>