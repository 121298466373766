<template>
  <el-dialog
    :visible.sync="dialogVisible" :close-on-click-modal="false" v-bind="$attrs" @close="close()" @opened="$emit('opened')" @open="$emit('open')">
    <template #title>
      <slot name="title"></slot>
    </template>
    <slot></slot>
    <div class="btn-group" v-if="showCancel || showConfirm">
      <el-button v-if="showCancel" plain @click="close()">{{cancelText}}</el-button>
      <el-button v-if="showConfirm" type="primary" :loading="confirmLoading" @click="confirm()">{{confirmText}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false
    }
  },
  props: {
    confirmText: {
      type: String,
      default: '确认'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    showConfirm: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    open() {
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding-bottom: 0;
}
::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: bold;
}
::v-deep .el-dialog__body {
  padding-top: 20px;
}
.btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  .btn-cancel, .btn-confirm {
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 12px;
    cursor: pointer;
  }
}
</style>